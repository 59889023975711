<template>
  <div class="wrap">
    <!-- <el-tabs v-model="activeName" class="tabs">
      常规任务
      <el-tab-pane label="任务分配" name="1">
        <el-button class="setting">通用设置</el-button> 
        <el-select
          v-model="queryParams.status"
          placeholder="其他区域设置"
          @change="resetPageAndRefresh"
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="list">
          <p class="listtit">通用任务</p>
          <div class="listcon">
            <el-select size="mini" class="selsct" v-model="queryParams.status" placeholder="营业额" @change="resetPageAndRefresh">
              <el-option 
                v-for="item in statusOptions" 
                :key="item.value" :label="item.label" 
                :value="item.value">
              </el-option>
            </el-select>
            <el-input size="mini" placeholder="请输入数值" v-model="input2">
              <template slot="append">剩余{{5000}}</template>
            </el-input><span style="color:#999">元</span> 
            <img src="" alt="">
          </div>
          
        </div>
     
      </el-tab-pane>
     
    </el-tabs> -->
    <img style="width:100%" src="../../assets/images/market/rwfp.png" alt="">
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PagedList from "@/components/common/PagedList";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'reaolveMessage',
  components: {
    PagedList
  },
  mixins: [pageMixin],
  data() {
    return{
      queryParams:{},
      activeName:'1',
      statusOptions:[],
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/common.scss";
@import "@/assets/scss/theme.scss";
.wrap{
  width: 100%; height: 100%;
  .tabs{ width: 100%; min-height: 100%; background:#fff;padding: 20px; padding-top: 0;
  }
  .big-box{ width: 100%; height: 100%; 
  }
}
.setting{ margin-right: 20px; }
.list{
  .listtit{ color: #222; line-height: 50px;}
  .listcon{
    .el-input:nth-child(n){ width: 120px; margin-right:10px ;}
    .el-input:nth-child(2n){ width: 200px;}
  }
}

</style>
